import React from "react";
import AbtImg from "../assets/abt1.png"
function Introduction() {
    return (
        <div className="max-w-6xl mx-auto py-20 mt-[50px]">
            <div className="flex justify-between gap-10 flex-wrap items-center sm:mx-0 mx-5">

                <div className="max-w-[600px]">
                    <h2 className="text-4xl font-semibold">INTRODUCTION</h2>
                    <p className="mt-3">
                        Mindshift consultancy and services Ltd Ltd is a business development service company established in 2019 to ensure that start-ups, sustainable and innovative businesses ease into and succeed in the ever changing economic ecosystem.
                        As new businesses are born they often enter into a hostile market, adorned with endless tides and rapids that almost always sink the boat before sale; we are here to ensure that they weather the storm and come out formidable
                    </p>
                </div>
                <div className="max-w-[400px]">
                    <img src={AbtImg} alt="" className="w-full" />
                </div>
            </div>
        </div>
    )
}

export default Introduction;