import service from "../assets/service.png";
import hold from "../assets/hold.png";
import mission from "../assets/mission.png";

export default function Objective() {
    return (
        <div className="max-w-6xl mx-auto flex flex-col items-center">
            {/* Objectives Section */}
            <div className="flex flex-wrap items-center gap-[40px] justify-between">
                <div className="bg-[#712C30] h-[350px] sm:w-[550px] w-[300px] sm:mx-0 mx-auto"></div>
                <div className="max-w-[550px] sm:mx-0 mx-5 space-y-6">
                    <h2 className="text-3xl font-bold">OUR OBJECTIVES</h2>
                    <p className="text-lg text-gray-700">
                        At Mindshift CS, we specialize in helping businesses thrive by producing
                        comprehensive services and proven strategies that have helped over a hundred
                        businesses increase their income rates and achieve remarkable growth.
                    </p>
                    <div className="text-left">
                        <ul className="space-y-4">
                            <li className="flex items-start space-x-4">
                                <img src={service} alt="Service" className="w-12 h-12" />
                                <span>
                                    Mindshift embodies a culture of service delivery that prioritizes creating
                                    the best experience and value for money for our customers.
                                </span>
                            </li>
                            <li className="flex items-start space-x-4">
                                <img src={hold} alt="Hold" className="w-12 h-12" />
                                <span>
                                    Mindshift holds a strong vision for the future, aligning our mid-term and
                                    long-term goals with it.
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Vision and Mission Section */}
            <div className="max-w-6xl mx-auto flex flex-wrap justify-between mt-12">
                {/* Vision Card */}
                <div className="bg-white shadow-lg border-l-4 border-[#712C30] rounded-lg p-6 sm:w-[48%] w-full sm:mx-0 mx-5">
                    <div className="flex items-center gap-2">
                        <div className="w-16 h-16 bg-[#712C30] rounded-full flex justify-center items-center">
                        </div>
                        <h3 className="font-semibold text-xl">VISION</h3>
                    </div>
                    <div>

                        <p className="mt-2">
                            To be synonymous with business growth and development across Africa, credited
                            with establishing and engineering the continent’s greatest firms.
                        </p>
                    </div>
                </div>

                {/* Mission Card */}
                <div className="bg-white shadow-lg border-l-4 border-[#712C30] rounded-lg p-6 sm:w-[48%] w-full sm:mx-0 mx-5 sm:mt-0 mt-5">
                    <div className="flex items-center gap-2">
                        <div className="w-16 h-16 bg-[#712C30] rounded-full flex justify-center items-center">
                            <img src={mission} alt="Mission" className="w-10 h-10" />
                        </div>
                        <h3 className="font-semibold text-xl">MISSION</h3>
                    </div>
                    <div>

                        <p className="mt-2">
                            To build and remain a customer-centric business known for providing
                            innovative and excellent solutions and services thereby creating a happy
                            community of satisfied clients.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
