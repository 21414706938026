import Step1 from "../assets/step1.png";
import Step2 from "../assets/step2.png";
import Step3 from "../assets/step3.png";
import Step4 from "../assets/step4.png";
import Line1 from "../assets/line1.png";
import Line2 from "../assets/line2.png";
import Line3 from "../assets/line3.png";

export default function Steps() {
    return (
        <div className="max-w-6xl mx-auto">
            <div className="w-full py-12 flex flex-col items-center">
                <div className="sm:mr-auto sm:mx-0 mx-5">
                    <h2 className="text-3xl font-bold mb-3 text-left">FOUR STEPS PROCESS</h2>
                    <p className="text-left text-lg font-semibold mb-12 max-w-2xl">
                        Our value proposition is built on four key pillars <br /> that distinguish us as the ultimate partner for <br /> startups seeking accelerated growth.
                    </p>
                </div>

                {/* Steps Container */}
                <div className="sm:relative flex flex-col items-center">
                    {/* First Step */}
                    <div className="flex items-center space-y-4 sm:ml-auto">
                        <img src={Step1} alt="Step 1" className="w-[300px]" />
                        <img src={Line1} alt="Line 1" className="w-[300px] sm:absolute sm:block hidden top-[30px] right-[300px] h-auto" />
                    </div>

                    {/* Second Step */}
                    <div className="flex items-center space-y-4 sm:mt-[70px] mt-5">
                        <img src={Step2} alt="Step 2" className="w-[300px] sm:mr-[550px]" />
                        <img src={Line2} alt="Line 2" className="w-[500px] sm:absolute sm:block hidden left-[300px] top-[350px] h-auto" />
                    </div>

                    {/* Third Step */}
                    <div className="flex items-center space-y-4 sm:ml-auto">
                        <img src={Line3} alt="Line 3" className="w-[300px] sm:relative sm:block hidden top-[100px] h-auto" />
                        <img src={Step3} alt="Step 3" className="w-[300px]" />
                    </div>

                    {/* Fourth Step */}
                    <div className="sm:pt-[90px] sm:mt-0 mt-5 sm:mr-[350px]">
                        <img src={Step4} alt="Step 4" className="w-[300px]" />
                    </div>
                </div>
            </div>
        </div>
    );
}
