// import Employees from "../components/Employees"
import Reseller from "../components/Introduction"

function About() {
    return (
        <div>
            <Reseller />
            {/* <Employees /> */}
        </div>
    )
}

export default About