import React, { useRef, useEffect, useState } from 'react';
import { MobileMenuIconLight } from './icons';
import { useLocation, useNavigate } from "react-router-dom";

const MobileMenu = () => {
  const modalRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for menu open/close

  const location = useLocation();
  const navigate = useNavigate();

  const pathMachRoute = (route) => {
    if (route === location.pathname) {
      return true;
    }
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeHamburger();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleHamburger = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  //   const openHamburger = () => {
  //     setIsMenuOpen(true);
  //   };

  const closeHamburger = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className='flex sm-custom:hidden cursor-pointer text-[30px]' onClick={toggleHamburger}>
      <MobileMenuIconLight />
      <div
        ref={modalRef}
        className={`fixed inset-0 h-[100%] w-[50%] mt-[86px] flex items-center justify-center  z-custom-index ${isMenuOpen ? 'block' : 'hidden'}`}
        id="hamburger"
        style={{ right: 0, left: 'auto', background: "linear-gradient(180deg, #712C30 0%, #712C30 100%)" }}
      >
        <div className="justify-center flex flex-col w-[100%] mx-[auto] h-[auto] items-center">
          <div className='gap-8 items-center flex flex-col list-none'>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/") && "text-black !border-b-[#F6D200]"}`} onClick={() => { navigate("/"); closeHamburger(); }}>Home</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/about") && "text-black !border-b-[#F6D200]"}`} onClick={() => { navigate("/hosting"); closeHamburger(); }}>About Us</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/services") && "text-black !border-b-[#F6D200]"}`} onClick={() => { navigate("/domain"); closeHamburger(); }}>Services</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/pricing") && "text-black !border-b-[#F6D200]"}`} onClick={() => { navigate("/technology"); closeHamburger(); }}>Pricing</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/blog") && "text-black !border-b-[#F6D200]"}`} onClick={() => { navigate("/help"); closeHamburger(); }}>Blog</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/contact") && "text-black !border-b-[#F6D200]"}`} onClick={() => { navigate("/help"); closeHamburger(); }}>Contact Us</li>
          </div>
          <div className='flex flex-col gap-3 items-center mt-2'>
            <button
              className={`cursor-pointer px-5 py-2 bg-[#712C30] border-white border-solid border-2 text-sm font-semibold text-white rounded-md ${(pathMachRoute("/sign-in") || pathMachRoute("/sign-in")) &&
                "text-black !border-b-red-500"
                }`}
              onClick={() => navigate("/sign-in")}
            >
              Sign In
            </button>
            <button
              className={`cursor-pointer px-5 py-2 bg-[#712C30] border-white border-solid border-2 text-sm font-semibold text-white rounded-md ${(pathMachRoute("/sign-up") || pathMachRoute("/sign-up")) &&
                "text-black !border-b-red-500"
                }`}
              onClick={() => navigate("/sign-up")}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
