import React, { useState } from "react";

function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your sign-in logic here

    if (rememberMe) {
      localStorage.setItem("rememberMe", email);
    } else {
      localStorage.removeItem("rememberMe");
    }

    alert(`Your details: Email - ${email}, Password - ${password}, Remember Me - ${rememberMe}`);
  };

  return (
    <div className="h-screen flex items-center justify-center bg-red-white">
      <div className="bg-gray-100 p-8 sm-custom:p-6 rounded-lg shadow-lg w-full max-w-md sm-custom:max-w-[300px]">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">
          Sign In
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={email}
              required
              placeholder="Your Email"
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border bg-transparent rounded-md focus:outline-none focus:ring border-blue-300"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              name="password"
              value={password}
              required
              placeholder="Password"
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 border bg-transparent rounded-md focus:outline-none focus:ring border-blue-300"
            />
          </div>
         <div className="flex justify-between">
         <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              name="rememberMe"
              checked={rememberMe}
              onChange={handleRememberMeChange}
              className="mr-2"
            />
            <label htmlFor="rememberMe" className="text-gray-800">Remember Me</label>
          </div>
          <div className="items-center">
            <span className="sm-custom:text-[12px]">
              <a
                href="http"
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-600"
              >
                Forgot password?
              </a>
            </span>
          </div>
         </div>

          <button
            type="submit"
            className="w-full bg-blue-400 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            LOGIN
          </button>
        </form>
        <div className=" flex flex-col items-center mt-5">
            <span className="sm-custom:text-[12px] text-center">
              Have no account yet?
              <a
                href="/sign-up"
                target="_blank"
                rel="noreferrer"
                className="hover:text-gray-600 underline"
              >
                Sign up
              </a>
            </span>
          </div>
      </div>
    </div>
  );
}

export default Signin;
