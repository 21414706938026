import React, { useState } from 'react';

const Signup = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    reenterPassword: '',
    acceptTerms: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.reenterPassword) {
      alert('Passwords do not match');
      return;
    }
    if (!formData.acceptTerms) {
      alert('You must accept the terms and conditions');
      return;
    }
    // Form submission logic here
    console.log('Form Data Submitted: ', formData); 
  };

  return ( 
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="bg-gray-100 p-8 sm-custom:p-6 rounded-lg shadow-lg w-full max-w-md sm-custom:max-w-[300px]">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              required
              placeholder='Full name'
              onChange={handleChange}
              className="w-full px-3 py-2 border bg-transparent rounded-md focus:outline-none focus:ring border-blue-300"
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              required
              placeholder='Your email'
              onChange={handleChange}
              className="w-full px-3 py-2 border bg-transparent rounded-md focus:outline-none focus:ring border-blue-300"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              name="password"
              placeholder='Password'
              value={formData.password}
              required
              onChange={handleChange}
              className="w-full px-3 py-2 border bg-transparent rounded-md focus:outline-none focus:ring border-blue-300"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              name="reenterPassword"
              placeholder='Re-enter Password'
              value={formData.reenterPassword}
              required
              onChange={handleChange}
              className="w-full px-3 py-2 border bg-transparent rounded-md focus:outline-none focus:ring border-blue-300"
            />
          </div>
          <div className="mb-4">
            <label className="text-gray-700 flex items-center">
              <input
                type="checkbox"
                name="acceptTerms"
                checked={formData.acceptTerms}
                required
                onChange={handleChange}
                className="mr-2"
              />
              <p className="sm-custom:text-[12px]">I read and accept all <a href="https://" target='_blank' rel="noreferrer" className="underline">terms of use...</a></p>
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-400 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Create An Account
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
