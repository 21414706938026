import Hero from "../components/Hero";
import Objective from "../components/Objective";
import OurServices from "../components/OurServices";
import Steps from "../components/Steps";
import company from "../assets/company.png"
import Team from "../components/Team";

function Home() {
  return (
    <>
      <Hero />
      <OurServices />
      <Steps />
      <Objective />
      <Team />
      <div className="flex flex-col items-center">
        <div className="max-w-[1100px] sm:mx-0 mx-5">
          <img src={company} alt="" />
        </div>
      </div>
    </>
  );
}

export default Home;
