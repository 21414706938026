import React from "react";
import John from "../assets/john.png"
import Ken from "../assets/ken.png"
import Dara from "../assets/dara.png"
import Akhi from "../assets/akih.png"
import { FaFacebook } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
const teamMembers = [
  {
    name: "John Iyoha",
    role: "Head of Partnerships / Managing Partner",
    image: John, // Replace with actual images
    socials: {
      linkedin: "#",
      facebook: "#",
      twitter: "#",
      instagram: "#"
    }
  },
  {
    name: "Kenneth Iguodala",
    role: "Chief of Operations / Managing Partner",
    image: Ken,
    socials: {
      linkedin: "#",
      facebook: "#",
      twitter: "#",
      instagram: "#"
    }
  },
  {
    name: "Dara Akerele",
    role: "Executive Admin / Head of Communications",
    image: Dara,
    socials: {
      linkedin: "#",
      facebook: "#",
      twitter: "#",
      instagram: "#"
    }
  },
  {
    name: "Akihgbe Eromosele",
    role: "Project Manager",
    image: Akhi,
    socials: {
      linkedin: "#",
      facebook: "#",
      twitter: "#",
      instagram: "#"
    }
  }
];

const Team = () => {
  return (
    <section className="py-[170px]">
      <h2 className="text-center text-4xl font-semibold mb-8">Meet Our Team</h2>
      <div className="flex flex-wrap justify-center space-x-4">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-[#D9D9D9] shadow-lg rounded-full p-6 w-[180px] h-[300px] text-center mb-6">
            <img
              className="w-[130px] h-[130px] mx-auto rounded-full mb-1"
              src={member.image}
              alt={`${member.name}`}
            />
            <h3 className="text-sm font-semibold">{member.name}</h3>
            <p className="text-black text-[11px]">{member.role}</p>
            <div className="flex justify-center items-center mt-4 space-x-1 text-white">
              {member.socials.facebook && (
                <a href={member.socials.facebook} target="_blank" rel="noreferrer" className="bg-[#712C30] p-1 rounded-full">
                  <FaFacebook />
                </a>
              )}
              {member.socials.linkedin && (
                <a href={member.socials.linkedin} target="_blank" rel="noreferrer" className="bg-[#712C30] p-1 rounded-full">
                  <FaLinkedinIn />
                </a>
              )}
              {member.socials.instagram && (
                <a href={member.socials.instagram} target="_blank" rel="noreferrer" className="bg-[#712C30] p-1 rounded-full">
                 <RiInstagramFill />
                </a>
              )}
              {member.socials.twitter && (
                <a href={member.socials.twitter} target="_blank" rel="noreferrer" className="bg-[#712C30] p-1 rounded-full">
                  <FaXTwitter />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
