import React from 'react';
import Human from '../assets/Human.png';
import Doc from '../assets/Doc.png';
import Strategy from '../assets/strategy.png';
import Project from '../assets/project.png';
import Training from '../assets/training.png';
import Brand from '../assets/brand.png';
import { Link } from 'react-router-dom';
const HostinOptions = () => {
    return (
        <div className="min-h-screen max-w-6xl mx-auto flex flex-col items-center justify-center text-center p-4 mt-10" >

            <div className="mb-12">
                <h1 className="text-xl text-[#712C30] rounded-full bg-white px-3 py-1 border-2 border-[#712C30] shadow-md w-[150px] mx-auto font-semibold">Our Services</h1>
                <p className="mt-2 text-xl sm:text-4xl font-bold">WHAT WE DO</p>
            </div>
            <div className='flex flex-wrap justify-center gap-[30px] w-full'>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    <PricingCard
                        title="Human capacity Development training"
                        image={Human}
                    />
                    <PricingCard
                        title="Business Documentation"
                        image={Doc}
                    />
                    <PricingCard
                        title="Business Strategy"
                        image={Strategy}
                    />
                    <PricingCard
                        title="Project Management"
                        image={Project}
                    />
                    <PricingCard
                        title="Cooperate Training"
                        image={Training}
                    />
                    <PricingCard
                        title="Brand Strategy"
                        image={Brand}
                    />
                </div>
            </div>

            <div className='ml-auto mr-20 font-semibold mt-3 text-[#712C30]'>
                <Link className='hover:underline' to="/services">
                    View More
                </Link>
            </div>
        </div>
    );
};

const PricingCard = ({ title, description, price, features, image }) => {
    return (
        <div className="border border-gray-200 rounded-2xl w-[280px] h-[280px] shadow-md p-6 bg-[#F5F5F5]  hover:cursor-pointer flex flex-col ">
            <div className='flex justify-center mt-10'>
                {image && <img src={image} alt={`${title} plan pic`} className="w-[100px]" />}
            </div>
            <div className=' flex flex-col justify-center mt-10'>
                <h2 className="text-xl  font-semibold text-center">{title}</h2>
            </div>
        </div>
    );
};

export default HostinOptions;
