import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png"
function Footer() {
    const location = useLocation();
    const navigate = useNavigate();

    const pathMatchRoute = (route) => {
        if (route === location.pathname) {
            return true;
        }
    };
    return (
        <>
            <div className="bg-[#121833] py-5 text-black mt-[20px]"
                style={{
                    background: "linear-gradient(0deg, #712C30 0%, rgba(246, 249, 255, 0.00) 82.55%)"
                }}>
                <div className="max-w-6xl mx-auto pt-20 flex justify-between md-down:flex-col md-down:px-3">
                    <div>
                        <div>
                            <div className="w-[100px]">
                                <Link to="/"><img src={Logo} className="w-full" alt="" /></Link>
                            </div>
                        </div>
                        <p className="max-w-[300px] mt-5 text-[13px]">The bedrock of a resilient business <br /> is the unwavering resilience of it’s <br /> entrepreneur. it takes a resilient <br /> person to build a resilient business.</p>
                        <hr className="mt-7 opacity-25" />
                    </div>
                    <ul className="items-center">
                        <h2 className="font-semibold text-[20px]">Company</h2>
                        <li title="Home Page" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/about") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/about")}>About Us</li>
                        <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/news") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/news")}>News Feed</li>
                        <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/contact") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/contact")}>Contact</li>
                        <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/affiliate") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/affiliate")}>Affiliate Program</li>
                        <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/technology") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/technology")}>Our Technology</li>
                        <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/knowledgebase") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/knowledgebase")}>Knowledgebase</li>
                    </ul>
                    
                    <ul className="items-center">
                        <h2 className="font-semibold text-[20px]">Join Our Newsletter</h2>
                        <li title="Products" className={`hover:title cursor py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent`}>We'll send you news and offers.</li>
                        <div className="bg-white px-2 w-[300px] py-2 rounded-full my-5">
                            <form action="" className="w-full flex justify-between items-center">
                                <input
                                    required
                                    type="email"
                                    placeholder="Enter Mail"
                                    className="border-none focus:outline-none text-black ml-3" />
                                <button type="submit" className="text-white bg-[#712C30] p-3 rounded-full"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.5 4a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V4.5H7a.5.5 0 01-.5-.5z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M12.354 3.646a.5.5 0 010 .708l-9 9a.5.5 0 01-.708-.708l9-9a.5.5 0 01.708 0z" clip-rule="evenodd"></path></svg></button>
                            </form>
                        </div>
                        <li className={`hover:title cursor py-1 text-[15px] font-light text-black border-b-[3px] border-b-transparent`}>Social Media</li>
                        <div className="flex flex-wrap gap-5">
                            <div className="border-2 border-opacity-25 rounded-md border-white p-2 border-solid">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"></path></g></svg>
                            </div>
                            <div className="border-2 border-opacity-25 rounded-md border-white p-2 border-solid">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"></path></svg>
                            </div>
                            <div className="border-2 border-opacity-25 rounded-md border-white p-2 border-solid">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                            </div>
                            <div className="border-2 border-opacity-25 rounded-md border-white p-2 border-solid">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z"></path></svg>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Footer;