import React from "react";
import bg from "../assets/hero.png"

import { motion } from "framer-motion";

function Hero() {
  return (
    <div className="h-[500px] bg-cover bg-center" style={{
      backgroundImage: `url(${bg})`
    }}>
      <div className="text-white pt-16 max-w-[500px] mx-auto ">

        <div className="flex flex-col items-center space-y-3 pt-20">
          <motion.div
            initial={{ opacity: 0 }} // Initial state
            animate={{ opacity: 1 }} // Final state
            transition={{ delay: 2, duration: 1 }} // Delay of 2 seconds, duration of 1 second
            className="md:text-xl 2xl:text-xl text-3xl font-medium"
          >
            Building your business
          </motion.div>

          <div className="">
            <motion.h1
              initial={{ opacity: 0 }} // Initial state
              animate={{ opacity: 1 }} // Final state
              transition={{ delay: 2, duration: 1 }} // Delay of 2 seconds, duration of 1 second
              className="top-0 left-0 text-4xl  font-semibold z-10"
            >
              IS OUR BUSINESS
            </motion.h1>
          </div>

          <div className="flex space-x-3 items-center  pt-5">
            <motion.button
              initial={{ opacity: 0 }} // Initial state
              animate={{ opacity: 1 }} // Final state
              transition={{ delay: 1, duration: 1 }} // Delay of 1 second, duration of 1 second
              className="md:text-lg text-sm border-2 flex space-x-2 bg-[#712C30] items-center px-4 py-2 rounded-md"
            >

              <a href="https://outlook.office365.com/owa/calendar/MindshiftConsultancyandServices@mindshiftcs.com/bookings/" target="_blank" rel="noreferrer" className="text-md 2xl:text-xl">
                Book Free consultation
              </a>
            </motion.button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Hero;

// linear-gradient(to right, #2D86FF 0%, #033AA7 100%)
// bg-gradient-to-b from-[#2D86FF] to[#033AA7]
