import React from 'react';
export const MobileMenuIconLight = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" stroke-width="0" className="mobile" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path></svg>
  );
};
export const Wordpress = () => {
  return (
    <div className="hover:!fill-blue-400 !fill-black">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.4146 32.0682L23.3208 52.6822C25.4398 53.3033 27.6801 53.6461 30.0014 53.6461C32.7539 53.6461 35.3957 53.1697 37.8518 52.3054C37.7887 52.2039 37.7312 52.0963 37.6842 51.9794L30.4146 32.0682Z" />
        <path d="M6.35522 29.9987C6.35522 39.3588 11.7947 47.4469 19.6823 51.279L8.40369 20.3763C7.09208 23.3169 6.35522 26.5712 6.35522 29.9987Z"  />
        <path d="M45.9615 28.8059C45.9615 25.8839 44.9128 23.8608 44.0133 22.2844C42.8143 20.3368 41.6907 18.688 41.6907 16.7397C41.6907 14.5663 43.3389 12.5432 45.6614 12.5432C45.7666 12.5432 45.8656 12.5562 45.9683 12.5611C41.7625 8.70858 36.1584 6.3551 30.0019 6.3551C21.74 6.3551 14.4723 10.5931 10.2461 17.0132C10.8004 17.0299 11.322 17.0416 11.7674 17.0416C14.2409 17.0416 18.07 16.7416 18.07 16.7416C19.3457 16.6649 19.4942 18.5376 18.2216 18.688C18.2216 18.688 16.9403 18.8383 15.5154 18.9138L24.1244 44.5255L29.2985 29.0058L25.6136 18.9132C24.3403 18.8377 23.1345 18.6873 23.1345 18.6873C21.8613 18.6131 22.0098 16.6655 23.2836 16.741C23.2836 16.741 27.1894 17.041 29.5113 17.041C31.9842 17.041 35.8145 16.741 35.8145 16.741C37.0902 16.6655 37.2393 18.537 35.9648 18.6873C35.9648 18.6873 34.6823 18.8377 33.2587 18.9132L41.8046 44.3306L44.163 36.4498C45.1844 33.1794 45.9615 30.8296 45.9615 28.8059Z" />
        <path d="M50.7475 18.6552C50.849 19.4075 50.9071 20.2173 50.9071 21.0866C50.9071 23.4858 50.4598 26.1833 49.1099 29.5557L41.8867 50.437C48.9156 46.3376 53.6454 38.7216 53.6454 30C53.646 25.8882 52.5949 22.0226 50.7475 18.6552Z" />
        <path d="M30.0011 0C13.4327 0 0.00170898 13.4316 0.00170898 30C0.00170898 46.5684 13.4327 60 30.0011 60C46.5695 60 60.0005 46.5684 60.0005 30C60.0005 13.4316 46.5695 0 30.0011 0ZM30.0023 56.2984C15.5004 56.2984 3.70392 44.502 3.70392 29.9988C3.70392 15.498 15.4998 3.70159 30.0023 3.70159C44.5024 3.70159 56.2983 15.4987 56.2983 29.9988C56.2989 44.5007 44.5018 56.2984 30.0023 56.2984Z" />
      </svg>
    </div>

  )
}